/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  CardMedia,
  IconButton,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  CircularProgress,
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Work as WorkIcon, Task as TaskIcon } from "@mui/icons-material";
import { isLoggedIn } from "../session";
import { auth, db, getToken, messaging } from "../firebase";
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  getDocs,
  addDoc,
  where,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import logoSvg from "../imgs/logo.svg";
import { requestNotificationPermission } from "./requestNotificationPermission";
import NotificationIcon, { subscribeToNotifications } from "./NotificationIcon";
import {
  useListenForNewChatMessages,
  useListenForProjectSkills,
  useListenForNewProposals,
} from "./listen";
import BottomNav from "./BottomNav";
import { styled } from "@mui/material/styles";

const TruncatedTypography = styled(Typography)(({ theme }) => ({
  textAlign: "justify",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
  lineHeight: "1.5em",
  maxHeight: "4.5em", // 3 lines * 1.5em line-height
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Home = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState("");
  const [authUserId, setAuthUserId] = useState(null);
  const [skills, setSkills] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [categories, setCategories] = useState([
    "Diseño Grafico",
    "Programación",
    "Marketing Digital",
    "Redacción De Contenidos",
    "Traducción",
    "Diseño Web",
  ]);
  const navigate = useNavigate();
  const [uid, setUid] = useState(null);
  const [proposals, setProposals] = useState([]);
  const [open, setOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogCC, setShowDialogCC] = useState(false);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const [projectIcons, setProjectIcons] = useState({});
  const [freelancers, setFreelancers] = useState([]);
  const [nameSearch, setNameSearch] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [allSkills, setAllSkills] = useState([]);

  useEffect(() => {
    // Suscribirse a notificaciones al montar el componente
    const unsubscribe = subscribeToNotifications(setHasNewNotifications);

    // Desuscribirse al desmontar el componente
    return () => unsubscribe && unsubscribe();
  }, []);

  useEffect(() => {
    const checkAuthAndRequestPermission = async () => {
      const loggedIn = await isLoggedIn();
      setUserAuthenticated(loggedIn);
      setLoading(false);

      if (loggedIn) {
        await requestNotificationPermission(messaging);
      }
    };

    checkAuthAndRequestPermission();
  }, []);

  const [userAuthenticated, setUserAuthenticated] = useState(false);

  useListenForNewChatMessages();

  useListenForProjectSkills();

  useListenForNewProposals();

  const [icon, setIcon] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paymentStatus = params.get(
      "mncjshahduayduycychajehdjxnsanmxncxkcoasodasdkjamxxcv"
    );
  
    if (paymentStatus === "pagado") {
      const { uid } = auth.currentUser || {};
  
      if (uid) {
        // 1. Get the membership document ID
        const getMembershipId = async () => {
          try {
            const userDocRef = doc(db, "users", uid);
            const membershipRef = collection(userDocRef, "memberships");
            const membershipSnapshot = await getDocs(membershipRef);
  
            if (!membershipSnapshot.empty) {
              return membershipSnapshot.docs[0].id; // Assuming there's only one membership
            } else {
              console.error("No membership found for user:", uid);
              return null;
            }
          } catch (error) {
            console.error("Error fetching membership ID:", error);
            return null;
          }
        };
  
        getMembershipId().then((membershipId) => {
          if (membershipId) {
            // 2. Update the membership document
            const membershipDocRef = doc(db, "users", uid, "memberships", membershipId);
            updateDoc(membershipDocRef, { paymentStatus: "pagado" })
              .then(() => {
                console.log("Membership payment status updated successfully");
                setShowDialog(true);
              })
              .catch((error) => {
                console.error(
                  "Error updating membership payment status:",
                  error
                );
              });
          }
        });
      } else {
        console.error("No se pudo obtener el ID de usuario actual");
      }
    }
  }, []);
  

  useEffect(() => {
    const fetchMembership = async () => {
      try {
        const { uid } = auth.currentUser || {};
        if (uid) {
          const userDocRef = doc(db, "users", uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            if (userData.userType === "freelancer") {
              const membershipRef = collection(userDocRef, "memberships");
              const membershipSnapshot = await getDocs(membershipRef);
              // Verificar si la subcolección memberships existe
              if (membershipSnapshot.empty) {
                setShowDialogCC(true);
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching memberships:", error);
      }
    };

    fetchMembership();
  }, []);

  useEffect(() => {
    const fetchIcon = async () => {
      try {
        const result = await getIconForUserType();
        setIcon(result);
      } catch (error) {
        console.error("Error fetching icon:", error);
        // Puedes manejar el error de manera adecuada, como mostrar un ícono predeterminado
      }
    };

    fetchIcon();
  });

  const getIconForUserType = useCallback(
    async (project) => {
      try {
        const { uid } = auth.currentUser || {};
        if (!uid) {
          return null;
        }

        // Fetch user data
        const userDocRef = doc(db, "users", uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          const userType = userData.userType;

          // Fetch memberships
          const membershipsRef = collection(userDocRef, "memberships");
          const membershipsSnapshot = await getDocs(membershipsRef);

          // Buscar una membresía con estado "pagado"
          const paidMembership = membershipsSnapshot.docs.find(
            (doc) => doc.data().paymentStatus === "pagado"
          );

          if (paidMembership) {
            return (
              <div>
                <Button
                  variant="outlined"
                  sx={{ mt: 2 }}
                  fullWidth
                  onClick={() => {
                    navigate(`/publishProposal?proyectId=${project.id}`);
                    handleClick(paidMembership.ref, paidMembership.data());
                  }}
                >
                  Enviar una propuesta
                </Button>
              </div>
            );
          } else {
            return (
              <div>
                <Button
                  variant="outlined"
                  sx={{ mt: 2 }}
                  fullWidth
                  disabled
                  href="/membership"
                >
                  Hazte miembro y envía una propuesta
                </Button>
              </div>
            );
          }
        }
        return null;
      } catch (error) {
        console.error("Error fetching icon:", error.message);
        return null;
      }
    },
    [navigate]
  );

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setLoading(true);
        const projectsCollectionRef = collection(db, "projects");
        const unsubscribe = onSnapshot(
          query(projectsCollectionRef, orderBy("createdAt", "desc")),
          async (snapshot) => {
            const projectsData = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setProjects(projectsData);

            // Extraer habilidades únicas de todos los proyectos
            const uniqueSkills = [
              ...new Set(
                projectsData.flatMap((project) =>
                  Array.isArray(project.skills)
                    ? project.skills
                    : [project.skills]
                )
              ),
            ];
            setAllSkills(uniqueSkills);

            // Fetch icons for each project
            const icons = {};
            for (const project of projectsData) {
              icons[project.id] = await getIconForUserType(project);
            }
            setProjectIcons(icons);

            setLoading(false);
            console.log("Proyectos obtenidos:", projectsData);
            console.log("Habilidades únicas:", uniqueSkills);
          }
        );
        return () => unsubscribe();
      } catch (error) {
        console.error("Error al obtener proyectos:", error);
        setLoading(false);
      }
    };
    fetchProjects();
  }, [getIconForUserType]);

  const handleClick = async (docRef, membershipData) => {
    setOpen(true);
    try {
      // Incrementar el contador de clics en la membresía
      await updateDoc(docRef, {
        clicks: membershipData.clicks
          ? String(Number(membershipData.clicks) + 1)
          : "1",
      });
    } catch (error) {
      console.error("Error updating clicks:", error.message);
    }
  };

  const handleNameSearchChange = (event) => {
    setNameSearch(event.target.value);
  };

  const handleSkillChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSkills(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    const checkAuthAndFetchProposals = async () => {
      if (!isLoggedIn()) {
        navigate("/login");
        return;
      }

      try {
        const token = await getToken();
        if (!token) {
          console.error("No se pudo obtener el token de autenticación.");
          return;
        }
        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error("No hay un usuario autenticado.");
          return;
        }
        const { uid } = currentUser;
        setUid(uid);
        setAuthUserId(uid); // Set authUserId to the current user's uid
        setLoading(true);

        // Obtener el userType desde la colección users
        const userDocRef = doc(db, "users", uid);
        const userDocSnapshot = await getDoc(userDocRef);
        if (!userDocSnapshot.exists()) {
          console.error("El documento de usuario no existe.");
          return;
        }
        const userData = userDocSnapshot.data();
        if (!userData.userType) {
          console.error("No se encontró userType para el usuario.");
          return;
        }
        const userType = userData.userType;

        const projectsSnapshot = await getDocs(collection(db, "projects"));
        const allProposals = [];
        for (const projectDoc of projectsSnapshot.docs) {
          const projectId = projectDoc.id;
          const projectData = projectDoc.data(); // Obtener datos del proyecto
          const proposalsSnapshot = await getDocs(
            collection(db, "projects", projectId, "proposals")
          );
          const projectProposals = proposalsSnapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              ...data,
              id: doc.id,
              uid: data.uid,
              projectId: projectId,
            };
          });
          allProposals.push(...projectProposals);
        }

        setProposals(allProposals);

        // Crear notificaciones para las nuevas propuestas
        const newNotifications = [];
        let hasUnreadNotifications = false;
        for (const proposal of allProposals) {
          // Evitar crear notificación para el propio freelancer
          if (proposal.uid === uid) {
            continue;
          }
          const notificationQuery = query(
            collection(db, "notifications"),
            where("idDoc", "==", proposal.id)
          );
          const notificationSnapshot = await getDocs(notificationQuery);
          if (notificationSnapshot.empty) {
            // Obtener el id del usuario dueño del proyecto (cliente)
            const projectDocRef = doc(db, "projects", proposal.projectId);
            const projectDocSnapshot = await getDoc(projectDocRef);
            if (!projectDocSnapshot.exists()) {
              console.error("El documento del proyecto no existe.");
              continue;
            }
            const projectData = projectDocSnapshot.data();
            const clientUid = projectData.uid; // Suponiendo que el campo uid representa al dueño del proyecto

            // Crear una notificación solo si el usuario autenticado es el dueño del proyecto
            if (userType === "cliente" && uid === clientUid) {
              const notificationData = {
                userId: clientUid, // Ahora, userId es el dueño del proyecto
                idDoc: proposal.id,
                userType: userType,
                read: false,
              };
              newNotifications.push(notificationData);
              // Guardar la notificación en Firestore
              await addDoc(collection(db, "notifications"), notificationData);
              console.log(
                "Notificación creada correctamente:",
                notificationData
              );

              hasUnreadNotifications = true; // Marcar que hay notificaciones no leídas
            }
          } else {
            console.log(
              "Notificación ya existente para idDoc:",
              `${proposal.projectId}_${proposal.id}`
            );
          }
        }
        setHasNewNotifications(hasUnreadNotifications);
        setLoading(false);
        console.log("Propuestas obtenidas:", allProposals);
      } catch (error) {
        console.error("Error al obtener propuestas:", error);
        setLoading(false);
      }
    };
    checkAuthAndFetchProposals();
  }, [navigate]);

  useEffect(() => {
    const fetchAuthUserId = async () => {
      const isAuthenticated = await isLoggedIn();
      const { uid } = auth.currentUser || {};
      setAuthUserId(isAuthenticated ? uid : null);
    };
    fetchAuthUserId();
  }, []);

  useEffect(() => {
    const filteredProjects = projects.filter(
      (project) =>
        project.title.toLowerCase().includes(nameSearch.toLowerCase()) &&
        (selectedSkills.length === 0 ||
          selectedSkills.some(
            (skill) =>
              project.skills.includes(skill) ||
              (typeof project.skills === "string" &&
                project.skills.includes(skill))
          ))
    );
    setFilteredResults(filteredProjects);
  }, [nameSearch, selectedSkills, projects]);

  const formatDate = (timeToDo) => {
    if (timeToDo instanceof Date) {
      return timeToDo.toLocaleDateString();
    } else if (timeToDo && timeToDo.toDate instanceof Function) {
      return timeToDo.toDate().toLocaleDateString();
    }
    return "";
  };

  const handleProject = (projectId) => {
    return () => {
      navigate(`/project/${projectId}`);
    };
  };

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    img: {
      maxWidth: "150px",
      maxHeight: "40px",
      display: "block",
      margin: "20px auto",
    },
    slogan: {
      color: "black",
      fontSize: "14px",
      fontWeight: "bold",
      paddingBottom: "-5px",
    },
    iconContainer: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      justifyContent: "space-around",
      color: "black",
    },
    iconContainerL: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      justifyContent: "space-around",
    },
    backSlogan: {
      display: "block",
      width: "100vw",
      height: "19px",
      backgroundColor: "#fff",
      textAlign: "center",
    },
    listItem: {
      display: "block",
      width: "95%",
      border: "0.5px solid #ccc",
      margin: "15px auto",
      boxShadow: "0px 0px 3px 0px black",
    },
    typography: {
      marginBottom: "0px",
    },
    contList: {
      marginTop: "-30px",
    },
    inputS: {
      width: "95%",
      height: "35px",
      marginTop: "20px",
      padding: "5px",
      fontSize: "14px",
      border: "3px solid grey",
      borderRadius: "4px",
    },
    buscar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "100%",
    },
    name: {
      color: "#312783",
      fontSize: "25px",
      fontWeight: "bold",
    },
    title: {
      fontSize: "24px",
      color: "dodgerblue",
      marginBottom: "7px",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "24px",
      width: "100%", // Changed from 97% to 100%
      border: "1px solid black",
      borderRadius: "10px",
    },
    description: {
      fontSize: "18px",
      marginBottom: "7px",
      width: "100%", // Ensure full width within the card
    },
    timeToDo: {
      color: "lightseagreen",
      fontSize: "15px",
      marginBottom: "7px",
    },
    skills: {
      marginBottom: "7px",
    },
    price: {
      color: "lightseagreen",
      marginBottom: "7px",
    },
    created: {
      fontSize: "10px",
      marginBottom: "7px",
    },
    media: {
      width: "190px",
      marginTop: "25px",
    },
  };

  const classes = {
    appBar: {
      top: "auto",
      bottom: 0,
      backgroundColor: "white",
      color: "black",
      borderTop: "1.5px solid black",
    },
    toolbar: {
      justifyContent: "space-around",
    },
  };

  return (
    <Container>
      <Dialog open={showDialogCC} onClose={() => setShowDialogCC(false)}>
        <DialogTitle>¡Hazte miembro de la red!</DialogTitle>
        <DialogContent>
          <Button href="/membership" fullWidth variant="outlined">
            Obtener Membresia
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>¡Pago exitoso!</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Su pago ha sido procesado con éxito.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <AppBar
        position="fixed"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "10px",
          bgcolor: "#fff",
        }}
      >
        <Toolbar
          sx={{ flexDirection: "column", width: "100%", alignItems: "center" }}
        >
          <CardMedia
            component="img"
            image={logoSvg} // Utilizar la variable que contiene la imagen SVG
            title="Skach"
            style={styles.media} // Especificar estilos si es necesario
          />
          <Box>
            <Typography variant="h6" sx={styles.slogan}>
              ¡Crea un proyecto o busca uno!
            </Typography>
          </Box>
          <Toolbar sx={styles.iconContainer}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/proyectos" color="#000">
                <WorkIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Mis Proyectos
              </Typography>
            </div>
            <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IconButton href="/notifications" color="black">
            <NotificationIcon hasNewNotifications={hasNewNotifications} />
          </IconButton>
          <Typography variant="caption" fontSize={9} align="center">
            Notificaciones
          </Typography>
        </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/propuestas" color="#000">
                <TaskIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Mis Propuestas
              </Typography>
            </div>
          </Toolbar>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 23, mb: 20 }}>
        <Box sx={styles.buscar}>
          <TextField
            fullWidth
            label="Buscar por título"
            variant="outlined"
            value={nameSearch}
            onChange={handleNameSearchChange}
            sx={{ mb: 2, mr: 1 }}
          />
          <FormControl fullWidth>
            <InputLabel id="skill-select-label">
              Seleccionar habilidades
            </InputLabel>
            <Select
              labelId="skill-select-label"
              multiple
              value={selectedSkills}
              onChange={handleSkillChange}
              input={<OutlinedInput label="Seleccionar habilidades" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {allSkills.map((skill) => (
                <MenuItem key={skill} value={skill}>
                  {skill}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <List>
              {filteredResults.map((project) => (
                <ListItem key={project.id}>
                  <Box style={styles.card}>
                    <Link onClick={handleProject(project.id)}>
                      <Typography style={styles.title}>
                        {project.title}
                      </Typography>
                    </Link>
                    <TruncatedTypography style={styles.description}>
                      {project.description}
                    </TruncatedTypography>
                    <Typography style={styles.price}>
                      Presupuesto: $ {project.price}
                    </Typography>
                    <Typography style={styles.skills}>
                      Habilidad: {project.skills}
                    </Typography>
                    <Typography style={styles.skills}>
                      Usuario: {project.name}
                    </Typography>
                    <Typography style={styles.timeToDo}>
                      Fecha de entrega: {formatDate(project.timeToDo)}
                    </Typography>
                    <Typography style={styles.created}>
                      Fecha de publicación:{" "}
                      {project.createdAt.toDate().toLocaleDateString()}
                    </Typography>
                    <Box style={{ width: "100%" }}>
                      {projectIcons[project.id]}
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
        <BottomNav />
      </Container>
    </Container>
  );
};

export default Home;
