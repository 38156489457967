import React, { useState, useEffect } from "react";
import "./index.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { CardMedia } from "@mui/material";
import Login from "./components/login";
import Register from "./components/register";
import Home from "./components/home";
import Profile from "./components/profile";
import Propuestas from "./components/propuestas";
import Proyectos from "./components/proyectos";
import Notifications from "./components/notifications";
import Publish from "./components/publish";
import PublishProposal from "./components/publishProposal";
import Edit from "./components/edit";
import ChatBox from "./components/chatbox";
import Chats from "./components/chats";
import Header3 from "./components/Header3";
import Project from "./components/project";
import Members from "./components/members";
import Atras from "./components/atras";
import BottomNav from "./components/BottomNav";
import Terms from "./components/terms";
import ProjectProposals from './components/projectProposals';
import Privacidad from "./components/privacyPolicy";
import { isLoggedIn, endSession } from "./session";
import logo from "./imgs/logo.png";
import Membership from "./components/membership";

const Loading = () => (
  <div className="loading-container">
    <CardMedia
      className="loading-img"
      alt="Cargando..."
      component="img"
      image={logo}
    />
  </div>
);

// Componente para proteger rutas que requieren autenticación
const ProtectedRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await isLoggedIn();
        setIsAuthenticated(!!user);
      } catch (error) {
        console.error("Error checking auth:", error);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

const AppRoutes = ({ user, handleLogout }) => {
  return (
    <Routes>
      {/* Rutas públicas */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacyPolicy" element={<Privacidad />} />

      {/* Ruta raíz */}
      <Route
        path="/"
        element={
          user ? (
            <Home handleLogout={handleLogout} />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />

      {/* Rutas protegidas */}
      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <Home handleLogout={handleLogout} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/notifications"
        element={
          <ProtectedRoute>
            <Notifications />
          </ProtectedRoute>
        }
      />
      <Route
        path="/propuestas"
        element={
          <ProtectedRoute>
            <Propuestas />
          </ProtectedRoute>
        }
      />
      <Route
        path="/chats"
        element={
          <ProtectedRoute>
            <Chats />
          </ProtectedRoute>
        }
      />
      <Route
        path="/chatbox/:projectId/:proposalId/:freelancerUid/:authUser"
        element={
          <ProtectedRoute>
            <ChatBox />
          </ProtectedRoute>
        }
      />
      <Route
        path="/project/:projectId/proposals/:proposalId"
        element={
          <ProtectedRoute>
            <ProjectProposals />
          </ProtectedRoute>
        }
      />
      <Route
        path="/project/:projectId"
        element={
          <ProtectedRoute>
            <Project />
          </ProtectedRoute>
        }
      />
      <Route
        path="/publish"
        element={
          <ProtectedRoute>
            <Publish />
          </ProtectedRoute>
        }
      />
      <Route
        path="/publishProposal"
        element={
          <ProtectedRoute>
            <PublishProposal />
          </ProtectedRoute>
        }
      />
      <Route
        path="/header3/:projectId"
        element={
          <ProtectedRoute>
            <Header3 />
          </ProtectedRoute>
        }
      />
      <Route
        path="/atras"
        element={
          <ProtectedRoute>
            <Atras />
          </ProtectedRoute>
        }
      />
      <Route
        path="/bottomNav"
        element={
          <ProtectedRoute>
            <BottomNav />
          </ProtectedRoute>
        }
      />
      <Route
        path="/members"
        element={
          <ProtectedRoute>
            <Members />
          </ProtectedRoute>
        }
      />
      <Route
        path="/proyectos"
        element={
          <ProtectedRoute>
            <Proyectos />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit"
        element={
          <ProtectedRoute>
            <Edit />
          </ProtectedRoute>
        }
      />
      <Route
        path="/membership"
        element={
          <ProtectedRoute>
            <Membership />
          </ProtectedRoute>
        }
      />

      {/* Ruta para manejar URLs no encontradas */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await isLoggedIn();
        setUser(user);
      } catch (error) {
        console.error("Error al verificar la autenticación:", error);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const handleLogout = async () => {
    try {
      await endSession();
      setUser(null);
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Router>
      <AppRoutes user={user} handleLogout={handleLogout} />
    </Router>
  );
};

export default App;