import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Toolbar,
  AppBar,
  Button,
  Box,
} from "@mui/material";
import Atras from "./atras";
import { collection, doc, getDocs, getDoc, setDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import BottomNav from "./BottomNav";

const Memberships = () => {
  const [userMembership, setUserMembership] = useState(null);

  const membership = {
    id: "Membresia",
    name: "Membresía",
    description: "Propuestas ilimitadas",
    price: "ARS $8000/mes",
    offer: "Los primeros 7 días gratis.",
    url: "https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c9380848f324171018f3528f7100078",
  };

  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchUserMembership = async () => {
      try {
        const userRef = doc(db, "users", currentUser.uid);
        const userSnapshot = await getDoc(userRef);

        if (userSnapshot.exists()) {
          const membershipsRef = collection(userRef, "memberships");
          const membershipsSnapshot = await getDocs(membershipsRef);
          
          if (!membershipsSnapshot.empty) {
            const membershipDoc = membershipsSnapshot.docs[0];
            setUserMembership({
              ...membershipDoc.data(),
              membershipId: membershipDoc.id,
            });
          }
        }
      } catch (error) {
        console.error("Error al obtener membresía del usuario:", error);
      }
    };

    fetchUserMembership();
  }, [currentUser.uid]);

  const handleConfirm = async () => {
    const userRef = collection(db, "users", currentUser.uid, "memberships");
    const docRef = doc(userRef);

    await setDoc(docRef, {
      name: membership.id,
      paymentStatus: "pendiente",
    });
    window.location.href = membership.url;
  };

  const renderMembershipStatus = () => {
    if (!userMembership) {
      return (
        <span style={{ color: "gray" }}>Aún no tienes ninguna membresía.</span>
      );
    }

    const status = userMembership.paymentStatus;

    if (status === "pendiente") {
      return (
        <span style={{ color: "orange" }}>Tu pago aún no se ha procesado.</span>
      );
    } else if (status === "pagado") {
      return (
        <span style={{ color: "lightgreen" }}>Tu membresía está activa.</span>
      );
    } else {
      return (
        <span style={{ color: "red" }}>Tu membresía ha sido cancelada.</span>
      );
    }
  };

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
      backgroundColor: "white",
      height: "100vh",
    },
    user: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "100%",
      height: "90px",
      marginTop: "10px",
      color: "black",
    },
    membershipContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "98%",
      marginTop: "20px",
      paddingBottom: "10px",
      textAlign: "left",
      backgroundColor: "white",
    },
    button: {
      display: "block",
      margin: "20px auto",
      width: "90%",
      marginBottom: "130px"
    },
    membershipBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      margin: "15px 0px",
      backgroundColor: "white",
      border: "1px solid black",
      borderRadius: "10px",
      color: "black",
      width: "100%",
      padding: "20px",
    },
    title: {
      color: "dodgerblue",
    },
    offer: {
      color: "dodgerblue",
    },
    boxStatus: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      margin: "15px 0px",
      backgroundColor: "black",
      borderRadius: "3px",
      color: "lightgreen",
      width: "100%",
      padding: "20px",
    },
    offers: {
      color: "green"
    }
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "dodgerblue" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Atras color="inherit" />
          <Container style={styles.user}>
            <Typography variant="h5" color="white" gutterBottom>
              Membresías
            </Typography>
          </Container>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 16 }}>
        <Container sx={styles.membershipContainer}>
          <Box sx={styles.membershipBox}>
            <Typography sx={styles.title} variant="h6">
              {membership.name}
            </Typography>
            <Typography>{membership.description}</Typography>
            <Typography sx={styles.offer}>{membership.price}</Typography>
            <Typography sx={styles.offers}>{membership.offer}</Typography>
          </Box>
          <Box sx={styles.boxStatus}>
            <Typography sx={{ color: "dodgerblue" }}>
              Estado de suscripción:
            </Typography>
            {renderMembershipStatus()}
          </Box>
        </Container>
        <Box sx={{ display: "block", textAlign: "center", width: "85%", padding: "15px", margin: "auto", border: "1.5px solid black", borderRadius: "5px" }}>
          <Typography sx={{ color: "red" }}>¡Importante!</Typography>
          <Typography>Luego de realizar el pago, por favor hacer click en "Volver al sitio.."</Typography>
        </Box>
        {!userMembership && (
          <Button variant="contained" sx={styles.button} onClick={handleConfirm}>
            Suscribirse
          </Button>
        )}
      </Container>
      <BottomNav />
    </Container>
  );
};

export default Memberships;